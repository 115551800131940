@import '../assets/scss/stories.scss';

.pagination-wrapper {
    display: flex;
    column-gap: 0.5rem;
    align-items: flex-end;
}
.pagination-container {
    display: flex;
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;

    .pagination-item {
        padding: 8px 12px;
        text-align: center;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        box-sizing: border-box;
        border-right: 1px solid rgba(124, 135, 156, 1);
        border-top: 1px solid rgba(124, 135, 156, 1);
        border-bottom: 1px solid rgba(124, 135, 156, 1);
        align-items: center;
        font-size: 13px;
        min-width: 32px;
        &:first-child {
            border-left: 1px solid rgba(124, 135, 156, 1);
            border-radius: 6px 0px 0px 6px;
            padding: 8px 18px;
        }
        &:last-child {
            border-radius: 0px 6px 6px 0px;
            padding: 8px 18px;
        }
        &.dots:hover {
            background-color: transparent;
            cursor: default;
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
            cursor: pointer;
        }

        &.selected {
            background-color: rgba(68, 76, 231, 1);
            color: #fff;
        }

        .arrow {
            &.left {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        &.disabled {
            pointer-events: none;
            svg {
                path {
                    fill: #d9dde2;
                }
            }
            &:hover {
                background-color: transparent;
                cursor: default;
            }
        }
    }
}
