@import '../../../assets/scss/colors';

.active-container {
    padding: 0.5rem;
    gap: 0.5rem;
    width: 9rem;
    height: 2.25rem;
    background: $success-50;
    border: 0.0625rem solid $success-100;
    border-radius: 1rem;
}

.inactive-container {
    padding: 0.5rem;
    gap: 0.5rem;
    width: 9rem;
    height: 2.25rem;
    background: $primary-gray-100;
    border: 0.0625rem solid $primary-gray-200;
    border-radius: 1rem;
    justify-content: center;
}

.modal-active-deactive {
    max-width: 21.875rem !important;
    max-height: 12.625rem !important;
    height: 12.625rem !important;
}

.modal-role-compare {
    max-width: 70rem !important;
    max-height: 19.375rem !important;
    height: 19.375rem !important;
}

.pending-container {
    padding: 0.5rem;
    gap: 0.5rem;
    width: 9rem;
    height: 2rem;
    background: $warning-100;
    border: 0.0625rem solid $warning-200;
    border-radius: 1rem;
    justify-content: center;
}

.deactivate-container {
    padding: 0.5rem;
    gap: 0.5rem;
    width: 12rem;
    height: 2.25rem;
    background: $error-50;
    border: 0.0625rem solid $error-100;
    border-radius: 0.25rem;
    justify-content: center;
}

.activate-container {
    padding: 0.5rem;
    gap: 0.5rem;
    width: 12rem;
    height: 2.25rem;
    background: $primary-indigo-50;
    border: 0.0625rem solid $primary-indigo-100;
    border-radius: 0.25rem;
    opacity: 1;
}

.blur-activate-container {
    padding: 0.5rem;
    gap: 0.5rem;
    width: 12rem;
    height: 2.25rem;
    background: $primary-indigo-50;
    border: 0.0625rem solid $primary-indigo-100;
    border-radius: 0.25rem;
    opacity: 0.4;
}

.button-container {
    padding: 0.5rem;
    gap: 1rem;
    width: 14rem;
    height: 2.5rem;
    justify-content: center;
}

.add-user-model {
    height: 15.875rem;
}

.buttonStyle {
    width: 8rem;
    justify-content: center;
}

.edit-container {
    padding: 0.5rem;
    gap: 0.5rem;
    width: 9rem;
    height: 2.25rem;
    background: $success-50;
    border: 0.0625rem solid $success-50;
    border-radius: 1rem;
    color: $success-700;
}

.view-container {
    padding: 0.5rem;
    gap: 0.5rem;
    width: 9rem;
    height: 2.25rem;
    background: $view-50;
    border: 0.0625rem solid $view-50;
    border-radius: 1rem;
    color: $view-700;
}

.no-access-container {
    padding: 0.5rem;
    gap: 0.5rem;
    width: 9rem;
    height: 2.25rem;
    background: $dataviz-red-50;
    border: 0.0625rem solid $dataviz-red-50;
    border-radius: 1rem;
}

.send-container {
    padding: 0.5rem;
    gap: 0.5rem;
    width: 15rem;
    height: 2.25rem;
}

.status-container {
    width: 8.75rem !important;
    height: 1.75rem !important;
    border-radius: 0.9375rem !important;
}

.active-btn {
    background: $success-50 !important;
    color: $success-700 !important;
}

.inactive-btn {
    background: $primary-gray-100 !important;
    color: $primary-gray-800 !important;
}

.pending-btn {
    background: $warning-50 !important;
    color: $warning-700 !important;
}

.badge-container {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    background: $error-100;
    border-radius: 0.5rem;
}

.sub-button{
    width:100%;
    justify-content: center;
}

.customer-container-style {
    max-width: 25rem !important;
    max-height: 25rem !important;
    height: 25rem !important;
}

.errorText{
    color: $error-700 !important;
}

.viewNormal{
    border-color: $base-black !important;
}

.viewError{
    border-color:$error-700 !important;
}

.user-update-style {
    max-width: 23.4375rem  !important;
    max-height: 18.75rem !important;
    height: 18.75rem !important;
}

.userErrorBlock{
    width:100% !important;
    background-color: $error-100;
    padding:1rem;
    gap:.25rem;
    border-radius: .25rem;
    margin-bottom: 1rem;
}
.ok-btn{
    width:100% !important;
    justify-content: center !important;
    text-align: center !important;
}