@import '../../assets/scss/colors';

.table {
    &-wrapper {
        padding: 0 0.5rem 0.25rem;

        background: $base-white;
        border: 0.0625rem solid $primary-gray-200;
        border-radius: 0.5rem;
    }
    &-component {
        width: 100%;
    }

    &-head {
        .table-row {
            border-bottom: 0.0625rem solid $primary-gray-200;
        }
        .table-cell {
            padding: 0.1875rem 0.625rem;
            .react-select-wrapper {
                display: inline-block;
                margin: -0.375rem 0;
            }

            .react-select-wrapper [class*='-ValueContainer'] {
                padding: 0;
            }
            .react-select-wrapper [class*='-control'],
            .react-select-wrapper [class*='-control']:hover {
                border: 0;
                min-height: auto;
                svg {
                    width: 0.5625rem;
                    height: 1.0625rem;
                }
            }
            .react-select-wrapper [class*='-singleValue'] {
                font-style: normal;
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 1.125rem;
                /* identical to box height, or 150% */

                display: flex;
                align-items: center;

                /* Gray/550 (med text) */

                color: #626b7f;
            }
            [class*='-Input'] {
                margin: 0;
                border: 0;
                padding: 0;
                display: grid;
                height: 1.125rem;
            }
            .is-open {
                box-shadow: none;
            }
        }
    }

    &-row {
        &:not(:last-child) {
            border-bottom: 0.0625rem solid $primary-gray-200;
        }
    }

    &-body {
        .table-cell {
            padding: 0.5rem 0.625rem;
        }
    }
}
