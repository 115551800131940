@import '../../assets/scss/colors';

.TinyPieChart {
    &-label {
        color: $primary-gray-550 !important;
        margin-right: 0.5rem;
    }
    &-wrapper {
    }
}
