@import '../../../assets/scss/colors';

.breaker-basic-config {
    display: grid;
    grid-template-columns: 0.6fr 2.2fr 2.2fr;
    gap: 1rem;
}

.breaker-main-config {
    display: grid;
    // grid-template-columns: 1fr 1fr; - Before PLT-1440 - Breaker Configration Division
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.p-default {
    padding: 2rem;
}

.tab-content {
    padding: 0rem !important;
}

.child-modal-style {
    opacity: 0.7 !important;
}

.sensor-chart-loader {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-visibility {
    overflow: visible !important;
}

.flag-count-container {
    background: $error-50 !important;
    border-color: $error-50 !important;
    width: 1.4375rem !important;
    height: 1.25rem !important;
    justify-content: center !important;
}

.flag-count-text {
    color: $error-700 !important;
    font-weight: 500 !important;
    font-size: 0.625rem !important;
    line-height: 1rem !important;
}

.custom-space-between {
    gap: 1rem;
}

.configured-breaker-style {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 0.5fr;
}

.unconfigured-breaker-style {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.breaker-modal-fullscreen {
    width: 92vw !important;
    max-width: none !important;
}

.breaker-modal-fullscreen .modal-dialog {
    margin: 0 !important;
    max-width: none !important;
}

.breaker-modal-fullscreen .modal-content {
    min-height: 92vh !important;
    border-radius: 0 !important;
}
