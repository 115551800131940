@import '../../assets/scss/colors';

.badge {
    &-wrapper {
        padding: 2px 10px;
        background: $primary-gray-100;
        border-radius: 10px;
        display: inline-flex;
        text-align: center;
        align-items: center;
        color: $primary-gray-550;
    }
}
