@import '../assets/scss/stories.scss';
@import '../../assets/scss/colors';
@import '../assets/scss/helpers';

.line-chart-wrapper {
    border: 1px solid $primary-gray-200;
    padding: 1rem;
    position: relative;

    border-radius: 0.5rem;

    .chart-header {
        padding-left: 0.625rem;
    }

    .chart-tooltip {
        @extend .box-shadow-lg;
        padding: 0.4375rem 0.8125rem;
        background: $base-white;

        border-radius: 0.25rem;
        overflow: hidden;

        border: 0.0625rem solid $primary-gray-450;

        td + td {
            padding-left: 1rem !important;
        }
    }
    .highcharts-plot-band {
        fill: $primary-gray-300;
    }

    .more-details-wrapper {
        display: flex;
        position: absolute;
        right: 1.625rem;
        bottom: 1.25rem;
        justify-content: flex-end;
        margin: 0.625rem 0rem;
    }
    .highcharts-menu {
        border-radius: 0.3125rem;
        border-color: $primary-gray-200;
    }
    .chart-header {
        padding-left: 0.625rem;
        display: flex;
        justify-content: space-between;
        .button-wrapper button {
            padding: 0.625rem 0.5625rem;
            margin-right: 0.625rem;
        }
        .unit-wrapper {
            margin-top: 0.125rem;
            .unit-value {
                margin-right: 0.375rem;
            }
            .unit {
                font-weight: 700;
                color: $primary-gray-800;
            }
        }
    }
    .highcharts-axis-labels text {
        color: $primary-gray-550 !important;
        font-weight: 600;
        font-size: 0.75rem !important;
    }
    .legend-text text tspan {
        cursor: pointer;
        font-weight: 600;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }

    .chart-legend-wrapper {
        display: flex;
        color: $primary-gray-550 !important;
        fill: $primary-gray-550 !important;
        align-items: center;
        justify-content: center;
        font-size: 0.875rem;
        font-weight: 400;
        .chart-legend-symbol {
            width: 0.5rem;
            height: 0.5rem;
            display: flex;
            border-radius: 50%;
            margin-right: 0.5rem;
        }
    }
    .highcharts-legend .highcharts-area-series rect {
        display: none;
    }
}

.line-chart-container {
    padding: 0;
    background: $base-white;
    min-height: 50vh;
    position: relative;
    display: flex;
    flex-direction: column;

    &-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 45vh;
        color: $primary-indigo-400;
    }
}
